.Home {
    text-align: center;
    height: 100vh;
    background-color: #f5f5f5;
}


.Header {
    text-align: left;
}

.Collapsed {
    margin-left: -35px;
}

.Breadcrumb {
    display: inline-block;
    margin-left: 15px;
}

.Fullscreen{
    display: inline;
    float: right;
    margin-top: 16px;
    margin-right: 40px;
}

.Logout {
    float: right;
    margin-top: 7px;
    margin-right: -120px;
    height: 50px;
    width: 50px;
    background: url("https://p.qqan.com/up/2021-5/16213049234897779.jpg");
    background-size: 100%;
    border-radius: 50px;
}


.Sider {
    height: 100vh;
    text-align: left;
}

.Logo {
    height: 45px;
    margin: 8px;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
}

.Logo>h2 {
    height: 100%;
    position: absolute;
    top: 15%;
    left: 45%;
    color: white;
    font-size: 20px;
    font-weight: normal;
}

.Logo>img {
    height: 100%;
    animation: login-logo-spin infinite 10s linear;
    position: absolute;
    top: 0%;
    left: 0%;
}

@keyframes login-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.TagList {
    margin-top: 5px;
    padding: 5px 15px;
    background-color: white;
    text-align: left;
}

.Content {
    margin: 20px 10px 0 10px;
    /* padding: 20px; */
    min-height: 200px;
    min-width: 600px;
    /* background: white; */
    /* border-radius: 15px; */
    max-height: 875px;
    overflow-y: auto;
}