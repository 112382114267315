* {
    margin: 0;
    padding: 0;
}

.login {
    position: relative;
    /* height: 100%; */
    height: 969px;
}

.login-from {
    /* margin: 180px auto; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    border: 1px solid #f0e9e9;
    width: 420px;
    padding: 35px;
    border-radius: 15px;
    box-shadow: 0 0 8px rgb(128, 124, 124);
    position: relative;
    background: rgba(187, 180, 180, 0.2);
}

.ant-form-item-explain-error {
    padding-left: 30px;
}

.foot-label {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, 80%);
    text-align: center;
}

.login-logo {
    height: 100px;
    pointer-events: none;
    animation: login-logo-spin infinite 10s linear;
    position: absolute;
    right: 0px;
    top: 50px;
}

@keyframes login-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}